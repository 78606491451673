* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ffffff;
  border-right: 1px solid #c9ced650;
  border-left: 1px solid #c9ced650;
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #c9ced650;
  border-top: 1px solid #c9ced650;
}

th {
  border-bottom: 1px solid #c9ced650;
  border-top: 1px solid #c9ced650;
}

tr:hover {
  background-color: #c9ced650;
}

/* tr:nth-child(even) {
  background-color: #C9CED650;
} */

.loading {
  height: 200px;
  position: relative;
}

.loading-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.offer-tag {
  text-align: center;
  background: #ff4d43;
  clip-path: polygon(100% 0, 100% 100%, 49% 75%, 0 100%, 0 0);
  width: 40px;
  height: 60px;
  margin-top: -32.5px;
  color: white;
}

.availiabity-tag {
  clip-path: polygon(100% 0, 76% 40%, 50% 100%, 23% 39%, 0 0);
  background: #ff4d43;
  margin-top: 10px;
  width: 40px;
  height: 30px;
}
.Membership-inline-slider .owl-stage {
  height: 100px;
}

/* 
.owl-prev {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 29%;
    margin-left: -20px;
    display: block !important;
    border: 0px solid black;
}

.owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 29%;
    right: -25px;
    display: block !important;
    border: 0px solid black;
}

.owl-prev i,
.owl-next i {
    transform: scale(1, 6);
    color: #ccc;
} */
.canvasjs-chart-credit {
  display: none !important;
}

/* invoice style */
.invoice-table {
  
  width: 100%;
  /* min-width: 400px; */
  border-spacing: 0px 15px;
}
.border-black{
  border: 1px solid black;
}
.border-black td{
  border: 1px solid black;
}
.border-black tr{
  border: 1px solid black;
}
.second-invoice-table{
  table-layout:unset;
  width: 100%;
}
.top-no-border{
  border-top: none  !important;
  border-left: none  !important;
}
.top-no-border td{
  border-top: none  !important;
  border-left: none  !important;
}
.bottom-no-border{
  border-bottom: none  !important;
}
.bottom-no-border td{
  border-bottom: none  !important;
}
.second-invoice-table tr{
  border-left: none  !important;
}
.second-invoice-table td{
  border-left: none  !important;
}
.left-data {
  text-align: right;
  width: 50%;
}
.right-data {
  width: 50%;
  text-align: left;
}
#invoice-address {
  text-align: center;
}
#transaction-head {
  text-align: center;
}
.address-small {
  font-size: 10px;
  line-height: 5px;
}
.invoice-logo {
  width: 40px;
  height: 40px;
  padding-left: 5px;
  padding-top: 0px;
}

@media only screen and (max-width: 990px) {
  .rotate-sm {
    margin-top: 20px;
    transform: rotate(-65deg);
    transform-box: fill-box;
  }
}
